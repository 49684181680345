<template>
  <v-container fluid>
    <v-row>
      <v-col>
        <h1>Working Time Directive</h1>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="col-4">
        <v-date-picker
          v-if="hidePicker === false"
          v-model="date"
          type="month"
        ></v-date-picker>
        <v-btn small @click="hidePicker = false" v-else>Show calendar</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-simple-table>
          <thead>
            <tr>
              <th>&nbsp;</th>
              <th v-for="(n, index) in days" :key="`days_${index}`">{{ n }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(crew, index) in crewMembers" :key="`cm_${index}`">
              <th>{{ crew.name }}</th>
              <td v-for="(n, index) in days" :key="`days_${index}`">
                <span v-for="(record, index) in crew.records" :key="`cr_${index}`">
                  <span
                    class="text-heavy"
                    :class="{ 'text-red' : record.minutes > record.max_shift_length }"
                    v-if="record.day === n">
                    {{ record.hours }}
                  </span>
                </span>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'WorkingTimeDirective',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    date() {
      this.getWorkingTimeReport();
    },
  },
  data() {
    return {
      crewMembers: [],
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 7),
      days: 0,
      hidePicker: false,
    };
  },
  methods: {
    getWorkingTimeReport() {
      const postData = {};
      postData.date = this.date;
      axios.post(`/dailyReturnSheets/getWorkingTime.json?token=${this.token}`, postData)
      .then((response) => {
        this.crewMembers = response.data.workRecords;
        this.days = parseInt(response.data.days);
        this.hidePicker = true;
      });
    },
  },
  mounted() {
    this.getWorkingTimeReport();
  },
};
</script>
  